import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25')
];

export const server_loads = [];

export const dictionary = {
		"/": [~3],
		"/contact-info": [~4],
		"/done/[[encoded_id]]": [~5],
		"/new-pharmacy": [~6],
		"/npi": [~7],
		"/other-network-selector": [~8],
		"/payment_selector": [~9],
		"/payments/success/[[route]]": [~10],
		"/pharmacy-selector": [~12],
		"/pharmacy": [~11],
		"/primary-network-selector": [~13],
		"/sentry-example": [14],
		"/service-selector": [~15],
		"/sms-confirmation": [~16],
		"/spn-selector": [~17],
		"/start": [~18],
		"/task-selector": [~19],
		"/test/contract": [20],
		"/test/email/preview": [~21,[2]],
		"/test/pdf": [~22],
		"/thank-you": [23],
		"/update_business_lead_confirm": [~25],
		"/update_business_lead": [~24]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';